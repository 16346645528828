export const ACCESS_TOKEN = "accessToken"

export const Roles = {
  1: "Assigned Study Coordinator",
  2: "Study Coordinator",
  3: "Principal Investigator",
  4: "Sub-Investigator",
  5: "Community Colleagues",
  6: "Other",
  7: "Committee Liaison",
  8: "Site Colleague"
}

export const Statuses = {
  1: {
    key: "enrollment_hold",
    title: "Enrollment on hold",
    color: "#FECC8B",
    disabled: "#FDE9CE",
    borderColor: "#FF9300",
  },
  2: {
    key: "enrollment_closed",
    title: "Enrollment Closed",
    color: "#FF5A5A",
    disabled: "#FAEAE7",
    borderColor: "#F50808",
  },
  3: {
    key: "enrolling_patients",
    title: "Enrolling Patients",
    color: "#A9E090",
    disabled: "#E4EDE0",
    borderColor: "#509A2E",
  },
  4: {
    key: "activated_enrollment_pending",
    title: "Site activated but enrollment pending",
    color: "#787879",
    disabled: "#EAECEF",
    borderColor: "#4B4A4A",
  },
  // 5: {
  //   key: "study_archived",
  //   title: "Study archived",
  //   color: "#787879",
  //   disabled: "#EAECEF",
  //   borderColor: "#787879",
  // },
}
