import React, { useState, useEffect } from "react"
import { useDispatch } from "react-redux"
import Switch from "react-switch"
import { loadEntity, updateEntity } from "../../store/entity/entity.actions"
import { addAlertWithTimeout, AlertType } from "../../utils/alertUtils"

const StudyVisibilitySettings = ({ study }) => {
  const dispatch = useDispatch()

  const [visibilitySettings, setVisibilitySettings] = useState(null)
  const [initialSettings, setInitialSettings] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState(null)
  const [isSaving, setIsSaving] = useState(false)
  const [saveError, setSaveError] = useState(null)

  // State to track changed fields
  const [changedSettings, setChangedSettings] = useState({})

  const defaultVisibilitySettings = Object.keys(visibilityLabels).reduce(
    (acc, setting) => {
      acc[setting] = true
      return acc
    },
    {}
  )

  useEffect(() => {
    if (!study?.id) {
      return
    }

    setIsLoading(true)
    setError(null)

    dispatch(loadEntity({ endpoint: `visibility-settings/${study.id}` }))
      .unwrap()
      .then(response => {
        setIsLoading(false)
        if (response?.data) {
          const combinedSettings = {
            ...defaultVisibilitySettings, // All settings default to true
            ...response.data, // Override with endpoint data
          }
          setVisibilitySettings(combinedSettings)
          setInitialSettings(combinedSettings)
        }
      })
      .catch(err => {
        setIsLoading(false)
        setError("Failed to load visibility settings.")
      })
  }, [dispatch, study.id]) // eslint-disable-line react-hooks/exhaustive-deps

  const toggleVisibility = setting => {
    // Get the current value, defaulting to true if undefined
    const currentValue =
      visibilitySettings[setting] !== undefined
        ? visibilitySettings[setting]
        : true
    const newValue = !currentValue

    // Update visibility settings
    const newVisibilitySettings = {
      ...visibilitySettings,
      [setting]: newValue,
    }

    setVisibilitySettings(newVisibilitySettings)

    // Compare with initial settings
    const initialValue =
      initialSettings[setting] !== undefined ? initialSettings[setting] : true
    if (newValue !== initialValue) {
      setChangedSettings({
        ...changedSettings,
        [setting]: newValue,
      })
    } else {
      const updatedChangedSettings = { ...changedSettings }
      delete updatedChangedSettings[setting]
      setChangedSettings(updatedChangedSettings)
    }
  }

  // Check if there are any changes
  const hasChanges = () => {
    return Object.keys(changedSettings).length > 0
  }

  const saveVisibilitySettings = () => {
    if (!hasChanges()) return

    setIsSaving(true)
    setSaveError(null)

    // Send only the changed fields to the backend
    dispatch(
      updateEntity({
        endpoint: `visibility-settings/${study.id}`,
        data: { settings: changedSettings },
      })
    )
      .unwrap()
      .then(() => {
        setIsSaving(false)
        setInitialSettings(visibilitySettings)
        setChangedSettings({})
        addAlertWithTimeout(
          "Success",
          <>
            <p>
              Visibility settings for this study have been successfully updated
            </p>
          </>,
          AlertType.Success
        )
      })
      .catch(err => {
        setIsSaving(false)
        setSaveError("Failed to save visibility settings.")
        addAlertWithTimeout(
          "Error",
          <>
            <p>Unable to update visibility settings for this study</p>
          </>,
          AlertType.Success
        )
      })
  }

  if (isLoading) {
    return <div>Loading...</div>
  }

  if (error) {
    return <div>{error}</div>
  }

  return (
    <div>
      <div
        style={{ display: "flex", flexDirection: "row", alignItems: "center" }}
      >
        <div>
          <h2 className="text-2xl font-bold mt-2 text-gray-800">
            Visibility Settings
          </h2>
          <p className="text-lg mb-6 text-gray-600">
            Set which parts of the app/study are visible to users
          </p>
        </div>

        <div style={{ marginLeft: "auto" }}>
          <button
            style={{
              backgroundColor: hasChanges() ? "#557FB3" : "#CCCCCC",
              color: hasChanges() ? "#FFFFFF" : "#888888",
            }}
            className={`px-4 py-2 rounded cursor-pointer ${
              !hasChanges() && "cursor-not-allowed"
            }`}
            onClick={saveVisibilitySettings}
            disabled={!hasChanges() || isSaving}
          >
            {isSaving ? "Saving..." : "Save Changes"}
          </button>
          {saveError && <p className="text-red-500 mt-2">{saveError}</p>}
        </div>
      </div>

      {visibilitySettings ? (
        <div>
          {Object.keys(categories).map(category => (
            <div key={category} className="mb-6">
              <h3 className="text-xl font-semibold mb-2 text-gray-700">
                {category}
              </h3>
              <div className="flex flex-wrap">
                {categories[category].map(setting => (
                  <div key={setting} className="w-full sm:w-1/2 px-4 py-2">
                    <label className="flex items-center justify-between">
                      <span className="">{visibilityLabels[setting]}</span>
                      <Switch
                        onChange={() => toggleVisibility(setting)}
                        checked={visibilitySettings[setting] !== false}
                        onColor="#557FB3"
                        offColor="#cccccc"
                        uncheckedIcon={false}
                        checkedIcon={false}
                      />
                    </label>
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      ) : (
        <div>
          <h2>Unable to retrieve the visibility settings for this study</h2>
        </div>
      )}
    </div>
  )
}

export default StudyVisibilitySettings

const visibilityLabels = {
    subTopic: 'Sub Topic',
    phase: 'Phase',
    prevPhaseResults: 'Previous Phase Results',
    agent: 'Agent Name',
    mechanismOfAction: 'Mechanism of Action',
    administration: 'Route/Frequency of Administration',
    studyName: 'Study Name',
    sideEffects: 'Side Effects & Risks',
    placebo: 'Placebo Information',
    protocolNumber: 'Protocol Number',
    fullProtocolName: 'Full Protocol Name',
    studyDuration: 'Study Duration',
    frequencyOfVists: 'Frequency of Visits',
    invasiveProcedures: 'Invasive Procedures',
    travelParking: 'Travel & Parking',
    medicalOfficer: 'Medical Officer',
    medicalMonitor: 'Medical Monitor',
    durationOfTreatment: "Duration of Treatment",
    siteBudget: 'Site Budget',
    comments: 'Comments',
    hideMedicalCondition: "Hide Medical Condition",
    hideStudyObjective: "Hide Study Objective",
    committeeTable: 'Committee Approval Table',
    studyBudget: 'Study Budget',
    consentForm: 'Consent Form',
    studySynopsis: 'Study Synopsis',
    studyProtocol: 'Study Protocol',
    studyStatus: 'Study Status',
    customNotifyMessage: 'Custom Notification Message',
    quickReferral: 'Quick Referral',
    chatBoard: 'Chat Board',
    supplInfo: 'Non-Site Team Members Can Access Suppl Info',
    topic: 'Topic',
    procedures: 'Procedures',
    instructionalVideo: 'Instructional Video',
    evaluationsAndAlgorithms: 'Evaluations and Algorithms',
    visitSchedule: 'Visit Schedule',
    results: 'Results',
    pkpd: 'PK/PD',
    addColleagueCommitteeLiaison: "Commitee Liaison",
    addColleagueOthers: "Others",
    addColleagueCommunity: "Community Colleague",
    addColleaguePI: "Principal Investigator",
    addColleagueSubPI: "Sub-Investigator",
  };
  
  const categories = {
      "Study Details": [
        'subTopic',
        'phase',
        'prevPhaseResults',
        'agent',
        'mechanismOfAction',
        'administration',
        'studyName',
        'sideEffects',
        'durationOfTreatment',
        'placebo',
        'protocolNumber',
        'fullProtocolName',
        'studyDuration',
        'frequencyOfVists',
        'invasiveProcedures',
        'travelParking',
        'medicalOfficer',
        'medicalMonitor',
        'siteBudget',
        'comments',
        'studyBudget',
        'consentForm',
        'studySynopsis',
        'studyProtocol',
      ],
      "Hide Additional Fields": [
        'hideMedicalCondition',
        'hideStudyObjective',
      ],
      "App Features": [
        'chatBoard',
        'supplInfo',
        'quickReferral',
        'customNotifyMessage',
        'committeeTable',
        'studyStatus'
      ],
      "Supplemental Info": [
        'topic',
        'procedures',
        'instructionalVideo',
        'evaluationsAndAlgorithms',
        'visitSchedule',
        'results',
        'pkpd'
      ],
      "Add Colleague Roles": [
        'addColleagueCommitteeLiaison',
        'addColleagueOthers',
        'addColleagueCommunity',
        'addColleaguePI',
        'addColleagueSubPI',
      ]
    };
