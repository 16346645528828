import ContentLoader from "react-content-loader"
import Input from "../Input"
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd"
import DragIcon from "@mui/icons-material/Menu"

const CriteriaList = ({
  data,
  study,
  edit,
  isLoading,
  handleDropCriteria,
  changeCriteria,
  removeCriteria,
  type,
}) => {
  const loadItem = (data, key) => {
    return (
      <div className="py-0.5" key={key}>
      <div
        className={`bg-white rounded-lg px-5 border ${
          isLoading ? "pt-5 pb-2" : "py-3"
        }`}
        style={{ width: "100%" }}
      >
        {data}
      </div>
    </div>
    )
  }

  return (
    <>
      {isLoading ? (
        [...Array(4)].map((el, index) =>
          loadItem(
            <ContentLoader
              speed={2}
              width="100%"
              height={24}
              backgroundColor="#c2c2c2"
              foregroundColor="#ecebeb"
            >
              <rect x="0" y="2" rx="8" ry="8" width="40%" height="12" />
            </ContentLoader>,
            index
          )
        )
      ) : edit ? (
        <DragDropContext onDragEnd={item => handleDropCriteria(item, type)}>
          <Droppable droppableId={type}>
            {provided => (
              <div {...provided.droppableProps} ref={provided.innerRef}>
                {study?.criteria[type].map((item, index) => (
                  <Draggable
                    key={index}
                    draggableId={`draggable-${index}`}
                    index={index}
                    isDragDisabled={
                      study.criteria[type].length === index + 1
                    }
                  >
                    {provided => (
                      <div
                        className="item-container"
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                      >
                        {loadItem(
                          <div className="flex flex-row items-center">
                            <div {...provided.dragHandleProps}>
                              <DragIcon
                                className={`-ml-2 mr-2 ${
                                  study.criteria[type].length ===
                                    index + 1 && "opacity-0"
                                }`}
                                style={{ color: "#446B95", fontSize: 18 }}
                              />
                            </div>
                            <Input
                              value={item.value ?? ""}
                              onChange={event => {
                                changeCriteria(
                                  study.criteria,
                                  index,
                                  event.target.value,
                                  type
                                )
                              }}
                              placeholder="Enter new criteria..."
                            />
                            {study.criteria[type].length !== index + 1 ? (
                              <span
                                className="cursor-pointer hover:underline text-red-500 ml-3"
                                onClick={() =>
                                  removeCriteria(index, type)
                                }
                              >
                                Remove
                              </span>
                            ) : (
                              <div className="w-20"></div>
                            )}
                          </div>,
                          index
                        )}
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      ) : (
        data &&
        data[type].map((item, index) =>
          loadItem(<p className="my-[5px]">{item.value}</p>, index)
        )
      )}
    </>
  )
}

export default CriteriaList
