import React, { useEffect, useRef, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import {
  deleteEntity,
  loadEntity,
  updateEntity,
} from "../../store/entity/entity.actions"
import { selectEntityData } from "../../store/entity/entity.selector"
import ContentLoader from "react-content-loader"
import Pagination from "../../components/Pagination"
import Button from "../../components/Button"
import Input from "../../components/Input"
import Modal from "../../components/Modal"
import { addAlertWithTimeout, AlertType } from "../../utils/alertUtils"
import cloneDeep from "lodash/cloneDeep"
import {
  formatDisplayField,
  updateNestedProperty,
  isEmpty,
} from "../../helpers"
import { clearEntity } from "../../store/entity/entity.reducer"
import EntityIcon from "../../components/EntityIcon"
import UploadAndCropImage from "../../components/UploadAndCropImage"
import SponsorsStudiesDataTable from "../../components/DataTables/SponsorsStudiesDataTable"

const SingleSponsorPage = () => {
  const { id } = useParams()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [edit, setEdit] = useState(false)
  const [sponsor, setSponsor] = useState({})
  const [uploadModal, setUploadModal] = useState(false)
  const [confirmDeleteModal, setConfirmDeleteModal] = useState(false)
  const [image, setImage] = useState(null)
  const [croppedImage, setCroppedImage] = useState(null)
  const [step, setStep] = useState(1)
  const [isUploading, setIsUploading] = useState(false)
  const [isUpdating, setIsUpdating] = useState(false)
  const uploadRef = useRef(null)
  const fields = [
    { title: "Contact first name", editable: true, key: "firstname" },
    { title: "Contact last name", editable: true, key: "lastname" },
    {
      title: "Phone number",
      editable: true,
      key: "phone",
      children: ["country_code", "phone"],
    },
    { title: "Email address", editable: true, key: "email" },
  ]
  let { isLoading, data } = useSelector(selectEntityData)

  const toggleEdit = () => {
    if (!edit) {
      setSponsor(data)
    }

    setEdit(!edit)
  }

  const save = () => {
    setIsUpdating(true)

    dispatch(updateEntity({ endpoint: "sponsors/" + id, data: sponsor }))
      .unwrap()
      .then(r => {
        setIsUpdating(false)

        if (r !== undefined) {
          dispatch(loadEntity({ endpoint: "users/" + id }))
          setEdit(false)
        }
      })
  }

  const deleteItem = () => {
    setConfirmDeleteModal(true)
  }

  const cancelEdit = () => {
    setEdit(!edit)
  }

  const changeField = (key, value) => {
    if (key.includes(".")) {
      let item = cloneDeep(sponsor)

      updateNestedProperty(item, key, value)

      setSponsor(item)
    } else {
      let item = { ...sponsor }

      item[key] = value

      setSponsor(item)
    }
  }

  const deleteAction = () => {
    dispatch(deleteEntity({ endpoint: `users/${data.id}` }))
      .unwrap()
      .then(response => {
        if (response !== undefined) {
          setConfirmDeleteModal(false)

          setTimeout(() => {
            addAlertWithTimeout(
              "Sponsor successfully deleted",
              <>
                <p>
                  <span className="font-bold">{data.sponsors_name}</span> has
                  been deleted successfully.
                </p>
              </>,
              AlertType.Warning
            )
          }, 500)

          return navigate(`/sponsors`, { replace: true })
        }
      })
  }

  const backAction = () => {
    setImage(null)
    setStep(1)
  }

  const closeUploadModal = () => {
    setUploadModal(false)
    setImage(null)
    setCroppedImage(null)
    setStep(1)
  }

  const onCropSubmit = () => {
    uploadRef.current.cropImageAction()

    setStep(3)
  }

  const onUploadSubmit = () => {
    setIsUploading(true)

    dispatch(
      updateEntity({
        endpoint: "users/update-profile-image/" + data.id,
        data: {
          image: croppedImage,
        },
      })
    )
      .unwrap()
      .then(response => {
        setIsUploading(false)

        if (response !== undefined) {
          addAlertWithTimeout(
            "Success",
            <>
              <p>Sponsor's profile image has been uploaded successfully.</p>
            </>,
            AlertType.Success
          )

          dispatch(loadEntity({ endpoint: "users/" + id }))

          closeUploadModal()

          setEdit(false)
        }
      })
  }

  useEffect(() => {
    dispatch(loadEntity({ endpoint: "users/" + id }))

    return () => dispatch(clearEntity())
  }, [dispatch, id])

  return (
    <>
      <Pagination
        data={
          data
            ? [
                { title: "Sponsors", link: "/sponsors" },
                {
                  title: `${data.sponsors_name}`,
                  link: `/sponsors/${data.id}`,
                },
              ]
            : null
        }
      />

      <div className="flex flex-col md:flex-row w-full bg-light-blue rounded-md border-2 py-8 px-8 lg:max-w-[85%] mb-5">
        <div className="flex flex-col sm:flex-row items-start sm:items-center">
          <div className="mr-6 flex flex-col text-left sm:text-center w-full max-w-[200px] mb-5">
            {isLoading ? (
              <ContentLoader
                speed={2}
                width="100%"
                height={24}
                backgroundColor="#c2c2c2"
                foregroundColor="#ecebeb"
              >
                <rect x="0" y="2" rx="8" ry="8" width="80%" height="12" />
              </ContentLoader>
            ) : (
              <EntityIcon
                src={data && data.image_path}
                size="xl"
                alt={data ? data["sponsors_name"] : ""}
                className="sm:m-auto"
              />
            )}

            {edit && (
              <Button
                className="mt-2 max-w-[120px] py-1 px-4 mx-auto"
                onClick={() => setUploadModal(true)}
              >
                Upload
              </Button>
            )}
            <div className="mt-3">
              {data &&
                (edit ? (
                  <div className="w-full">
                    <Input
                      id="sponsors_name"
                      value={sponsor["sponsors_name"]}
                      onChange={event => {
                        changeField("sponsors_name", event.target.value)
                      }}
                    />
                  </div>
                ) : (
                  <span className="font-medium w-full">
                    {data["sponsors_name"]}
                  </span>
                ))}
            </div>
          </div>
          <div className="flex grow flex-wrap">
            {fields.map((field, index) => (
              <div className="w-[220px] mb-5 mr-5" key={index}>
                <span className="text-sm text-light-gray">{field.title}</span>
                <br />
                {isLoading && (
                  <ContentLoader
                    speed={2}
                    width="100%"
                    height={24}
                    backgroundColor="#c2c2c2"
                    foregroundColor="#ecebeb"
                  >
                    <rect x="0" y="2" rx="8" ry="8" width="80%" height="12" />
                  </ContentLoader>
                )}
                {data &&
                  (edit ? (
                    field.children ? (
                      <div className="pr-5">
                        {field.children.map(item => (
                          <Input
                            key={`${field.key}.${item}`}
                            id={`${field.key}.${item}`}
                            className="mb-1"
                            value={sponsor[field.key][item]}
                            placeholder={formatDisplayField(item)}
                            onChange={event => {
                              changeField(
                                `${field.key}.${item}`,
                                event.target.value
                              )
                            }}
                          />
                        ))}
                      </div>
                    ) : (
                      <div className="pr-5">
                        <Input
                          id={field.key}
                          value={sponsor[field.key]}
                          placeholder={field.title}
                          onChange={event => {
                            changeField(field.key, event.target.value)
                          }}
                        />
                      </div>
                    )
                  ) : field.children ? (
                    (() => {
                      let empty = false

                      return field.children.map((item, index) => {
                        if (!empty && isEmpty(data[field.key][item])) {
                          empty = true

                          return (
                            <span key={item + index} className="font-light">
                              (Empty)
                            </span>
                          )
                        } else {
                          return (
                            <span key={item + index} className="font-medium">
                              {data[field.key][item]}
                            </span>
                          )
                        }
                      })
                    })()
                  ) : isEmpty(data[field.key]) ? (
                    <span className="font-light">(Empty)</span>
                  ) : (
                    <span className="font-medium">{data[field.key]}</span>
                  ))}
              </div>
            ))}
          </div>
        </div>
        <div className="flex flex-col">
          <Button
            onClick={edit ? save : toggleEdit}
            loading={isUpdating || isLoading}
          >
            {isUpdating ? "..." : edit ? "Save" : "Edit"}
          </Button>
          <Button
            onClick={edit ? cancelEdit : deleteItem}
            className="text-red-500"
            loading={isUpdating || isLoading}
          >
            {edit ? "Cancel" : "Delete"}
          </Button>
        </div>
      </div>

      <div className="pb-5">
        {data && <SponsorsStudiesDataTable sponsorId={data.id} />}
      </div>

      <Modal
        title="Upload image"
        size="lg"
        isShown={uploadModal}
        setIsShown={setUploadModal}
        onSubmit={step === 2 ? onCropSubmit : onUploadSubmit}
        submitActionActive={
          (step === 2 && image) || (step === 3 && croppedImage && !isUploading)
        }
        submitActionLoading={isUploading}
        onClose={closeUploadModal}
        leftButtonText="Skip"
        leftButtonAction={closeUploadModal}
        secondaryText="Back"
        secondaryAction={backAction}
        secondaryActionActive={image}
      >
        <UploadAndCropImage
          ref={uploadRef}
          image={image}
          setImage={setImage}
          croppedImage={croppedImage}
          setCroppedImage={setCroppedImage}
          initialStep={1}
          currentStep={step}
          setStep={setStep}
          handlerCancel={backAction}
          type="jpeg"
          cropShape="round"
        />
      </Modal>

      <Modal
        title="Delete Sponsor"
        size="md"
        isShown={confirmDeleteModal}
        setIsShown={setConfirmDeleteModal}
        onSubmit={deleteAction}
        submitText="Yes"
      >
        <p>
          Are you sure you want to delete{" "}
          <span className="font-bold">{data && `${data.sponsors_name}`}</span>
        </p>
      </Modal>
    </>
  )
}

export default SingleSponsorPage
