import React, { useEffect, useRef, useState } from "react";
import ArrowDown from "@mui/icons-material/KeyboardArrowDown"

const Accordion = props => {
  const contentEl = useRef()
  const [contentHeight, setContentHeight] = useState(0);
  const { handleToggle, active, children, title, id, icon, edit } = props


  useEffect(() => {
    if (contentEl.current) {
      setContentHeight(contentEl.current.scrollHeight); 
    }
  }, []);

  const displayHeight = edit ? contentHeight + 20 : contentHeight;

  return (
    <div className="bg-white rounded-lg px-4 my-2 border border-color-lightgray flex flex-col overflow-hidden py-2">
      <div
        className="flex items-center cursor-pointer content-between transition duration-300"
        onClick={() => handleToggle(id)}
      >
        {icon}
        <span className="font-medium text-lg text-dark-gray flex-grow mt-0.5">
          {title}
        </span>
        <ArrowDown
          className={`mt-0.5 transition duration-300 ${
            active === id ? "" : "-rotate-90"
          } `}
          style={{ color: "#446B95", fontSize: 36 }}
        />
      </div>
      <div
        ref={contentEl}
        className={`relative h-0 overflow-hidden transition-[height] duration-200 ${
          active === id ? "h-auto" : ""
        }`}
        style={
          active === id
            ? { height: `${displayHeight}px` }
            : { height: "0" }
        }
      >
        <div>{children}</div>
      </div>
    </div>
  )
}

export default Accordion
