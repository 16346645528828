import Button from "../Button"
import ContentLoader from "react-content-loader"
import React, { useState } from "react"
import Accordion from "../Accordion"
import cloneDeep from "lodash/cloneDeep"
import TextArea from "../Textarea"

const Details = ({
  data,
  study,
  setStudy,
  isLoading,
  updateStudy,
  save,
  editingOnly = false,
}) => {
  const [edit, setEdit] = useState(editingOnly)
  const [accordions, setAccordions] = useState({
    sub_topic: { active: true },
    protocol: { active: true },
    full_protocol_name: { active: true },
    study_objective: { active: true },
    medical_condition: { active: true },
    phase: { active: true },
    agent: { active: true },
    mechanism: { active: true },
    administration: { active: true },
    side_effects: { active: true },
    randomization: { active: true },
    schedule: { active: true },
    interventions: { active: true },
    travel_parking_costs: { active: true },
    medical_officer: { active: true },
    budget: { active: true },
    comments: { active: true },
  })

  const handleAccordionToggle = id => {
    let updated = cloneDeep(accordions)
    updated[id]["active"] = !updated[id]["active"]
    setAccordions(updated)
  }

  const onItemChange = (value, key) => {
    const updatedStudy = cloneDeep(study)

    updatedStudy[key] = value

    setStudy(updatedStudy)
  }

  const loadItem = (index, title = null) => (
    <div className="mt-1 mb-1 flex flex-col font-light" key={`${index}-item`}>
      {title && <span className="font-medium flex-grow">{title}</span>}
      {edit ? (
        <TextArea
          id={index}
          value={study && study[index]}
          onChange={event => onItemChange(event.target.value, index)}
          placeholder="Enter value"
        />
      ) : (
        data &&
        (data[index] ?? <span className="text-light-gray">(Empty)</span>)
      )}
    </div>
  )

  const loadAccordion = (index, title, content) => (
    <Accordion
      key={`${index}-accordion`}
      active={accordions[index]["active"] ? index : null}
      title={title}
      handleToggle={handleAccordionToggle}
      id={index}
      edit={edit}
    >
      {content}
    </Accordion>
  )

  return (
    <div>
      {!editingOnly && (
        <div className="flex flex-row justify-start items-center">
          {edit && (
            <Button
              className="px-8 py-1.5 mb-0 mr-2 text-green-700"
              onClick={() => {
                save()
                setEdit(false)
              }}
            >
              Save
            </Button>
          )}
          <Button
            className="px-3 py-1.5 mb-0"
            onClick={() => {
              if (!edit) {
                updateStudy()
              }
              setEdit(prev => !prev)
            }}
          >
            {edit ? (
              "Cancel"
            ) : (
              <svg
                width="19"
                height="19"
                viewBox="0 0 19 19"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M17.71 5.04C18.1 4.65 18.1 4 17.71 3.63L15.37 1.29C15 0.899998 14.35 0.899998 13.96 1.29L12.12 3.12L15.87 6.87M0 15.25V19H3.75L14.81 7.93L11.06 4.18L0 15.25Z"
                  fill="black"
                />
              </svg>
            )}
          </Button>
        </div>
      )}
      <div className="mt-2.5">
        {isLoading ? (
          [...Array(9)].map((el, index) => (
            <div
              className="bg-white rounded-lg px-5 border pt-5 pb-2 mb-4"
              key={index}
            >
              <ContentLoader
                speed={2}
                width="100%"
                height={24}
                backgroundColor="#c2c2c2"
                foregroundColor="#ecebeb"
              >
                <rect x="0" y="2" rx="8" ry="8" width="15%" height="12" />
              </ContentLoader>
              <ContentLoader
                speed={2}
                width="100%"
                height={24}
                backgroundColor="#c2c2c2"
                foregroundColor="#ecebeb"
              >
                <rect x="0" y="2" rx="8" ry="8" width="55%" height="12" />
              </ContentLoader>
            </div>
          ))
        ) : (
          <>
            {loadAccordion("sub_topic", "Sub-topic", loadItem("sub_topic"))}
            {loadAccordion("protocol", "Protocol Number", loadItem("protocol"))}
            {loadAccordion(
              "full_protocol_name",
              "Full Protocol Name",
              loadItem("full_protocol_name")
            )}
            {loadAccordion(
              "medical_condition",
              "Medical Condition",
              loadItem("medical_condition")
            )}
            {loadAccordion(
              "study_objective",
              "Study Objective",
              loadItem("study_objective")
            )}
            {loadAccordion(
              "phase",
              "Phase",
              <>
                {loadItem("phase", "Phase")}
                {loadItem("previous_phase_results", "Previous Phase Results")}
              </>
            )}
            {loadAccordion("agent", "Agent/Interventions", loadItem("agent"))}
            {loadAccordion(
              "mechanism",
              "Principal Mechanism of Action",
              loadItem("mechanism")
            )}
            {loadAccordion(
              "administration",
              "Route & Frequency of Administration",
              loadItem("administration")
            )}
            {loadAccordion(
              "side_effects",
              "Side Effects/ Risks of Intervention",
              loadItem("side_effects")
            )}
            {loadAccordion(
              "randomization",
              "Drug/ Placebo Randomization",
              loadItem("randomization")
            )}
            {loadAccordion(
              "schedule",
              "Study schedule",
              <>
                {loadItem("duration", "Duration of Treatment/ Study")}
                {loadItem("assessment_frequency", "Frequency of Clinic Visits")}
              </>
            )}
            {loadAccordion(
              "interventions",
              "Invasive Procedures",
              loadItem("interventions")
            )}
            {loadAccordion(
              "travel_parking_costs",
              "Travel / Parking costs",
              loadItem("travel_parking_costs")
            )}
            {/*TODO:CRA*/}
            {loadAccordion(
              "medical_officer",
              "Medical Officer",
              <>
                {loadItem("medical_officer_name", "Medical Officer Name")}
                {loadItem(
                  "medical_officer_contact_email",
                  "Medical Officer Email"
                )}
                {loadItem(
                  "medical_officer_contact_phone",
                  "Medical Officer Phone"
                )}
                {loadItem("medical_officer_contact_fax", "Medical Officer Fax")}
              </>
            )}
            {loadAccordion("budget", "Site Budget", loadItem("budget"))}
            {loadAccordion("comments", "Comments", loadItem("comments"))}
          </>
        )}
      </div>
    </div>
  )
}

export default Details
