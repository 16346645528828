import Button from "../Button"
import { useEffect, useState } from "react"
import cloneDeep from "lodash/cloneDeep"
import { isEmpty } from "../../helpers"
import CriteriaList from "./CriteriaList";

const Criteria = ({ data, study, setStudy, isLoading, updateStudy, save }) => {
  const [edit, setEdit] = useState(false)

  const changeCriteria = (criteria, key, value, type) => {
    const updatedCriteria = [...criteria[type]]
    const updatedStudy = cloneDeep(study)

    updatedCriteria[key] = { ...updatedCriteria[key], value }

    updatedStudy["criteria"][type] = updatedCriteria

    setStudy(updatedStudy)
  }

  const removeCriteria = (index, type) => {
    let updatedStudy = cloneDeep(study)

    updatedStudy.criteria[type].splice(index, 1)

    setStudy(updatedStudy)
  }

  const handleDropCriteria = (droppedItem, list) => {
    if (!droppedItem.destination) {
      return
    }
    let updatedStudy = cloneDeep(study)
    let updatedList = cloneDeep(study["criteria"][list])

    const [reorderedItem] = updatedList.splice(droppedItem.source.index, 1)

    updatedList.splice(droppedItem.destination.index, 0, reorderedItem)

    updatedStudy["criteria"][list] = updatedList

    setStudy(updatedStudy)
  }

  useEffect(() => {
    if (edit && study?.criteria) {
      if (
        !isEmpty(
          study?.criteria?.inclusion[study.criteria.inclusion.length - 1].value
        )
      ) {
        let updatedStudy = cloneDeep(study)

        updatedStudy.criteria.inclusion.push({ show: true, value: "" })

        setStudy(updatedStudy)
      }

      if (
        !isEmpty(
          study?.criteria?.exclusion[study.criteria.exclusion.length - 1].value
        )
      ) {
        let updatedStudy = cloneDeep(study)

        updatedStudy.criteria.exclusion.push({ show: true, value: "" })

        setStudy(updatedStudy)
      }
    }
  }, [edit, setStudy, study])

  return (
    <div>
      <div className="flex flex-row justify-between items-center">
        <div>
          {edit && (
            <Button
              className="px-8 py-1.5 mb-0 mr-2 text-green-700"
              onClick={() => {
                save()
                setEdit(false)
              }}
            >
              Save
            </Button>
          )}
          <Button
            className="px-3 py-1.5 mb-0"
            onClick={() => {
              if (!edit) {
                updateStudy()
              }
              setEdit(prev => !prev)
            }}
          >
            {edit ? (
              "Cancel"
            ) : (
              <svg
                width="19"
                height="19"
                viewBox="0 0 19 19"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M17.71 5.04C18.1 4.65 18.1 4 17.71 3.63L15.37 1.29C15 0.899998 14.35 0.899998 13.96 1.29L12.12 3.12L15.87 6.87M0 15.25V19H3.75L14.81 7.93L11.06 4.18L0 15.25Z"
                  fill="black"
                />
              </svg>
            )}
          </Button>
        </div>
      </div>
      <div className="flex flex-row space-x-5">
  <div className="mt-2.5 w-full box-border">
  <h4 className="font-bold mb-2.5">Inclusion criteria</h4>
    <CriteriaList
      data={data}
      study={study}
      edit={edit}
      isLoading={isLoading}
      handleDropCriteria={handleDropCriteria}
      changeCriteria={changeCriteria}
      removeCriteria={removeCriteria}
      type="inclusion"
    />
  </div>

  <div className="mt-2.5 w-full box-border">
    <h4 className="font-bold mb-2.5">Exclusion criteria</h4>
    <CriteriaList
      data={data}
      study={study}
      edit={edit}
      isLoading={isLoading}
      handleDropCriteria={handleDropCriteria}
      changeCriteria={changeCriteria}
      removeCriteria={removeCriteria}
      type="exclusion"
    />
  </div>
</div>
    </div>
  )
}

export default Criteria
